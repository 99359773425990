<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <div class="intro-form">
      <h1>Glemt passord?</h1>
      <p>Skriv inn e-postadressen du registrerte deg med og få tilsendt en lenke for å sette nytt passord.</p>

      <div class="form-row">
        <label class="label" for="email">E-post</label>
        <input
          class="input"
          id="email"
          type="email"
          required
          v-model="email"
          autocomplete="login username"
        />
      </div>
      
      <div class="btn-wrapper" v-if="!passwordResetEmailSent">
        <button type="submit" class="btn" @click="requestPasswordReset()">Send meg lenke</button>
      </div>
      <div class="btn-wrapper" v-else>
        <p>Da kan du sjekke e-posten din og følge instruksene for å sette nytt passord.</p>
      </div>
    </div>

    <p  v-if="!passwordResetEmailSent" class="u-text-center">
      eller gå tilbake til
      <router-link
        :to="
          $route.query.returnUrl
            ? `/login?returnUrl=${$route.query.returnUrl}`
            : '/login'
        "
      >innlogging</router-link>.
    </p>
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref } from "vue";
import { fAuth } from "../registerFirebase";

export default defineComponent({
  setup() {
    const email = ref("");
    const passwordResetEmailSent = ref(false);

    const requestPasswordReset = () => {
      fAuth.sendPasswordResetEmail(email.value)
        .then(() => {
          passwordResetEmailSent.value = true;
        })
    };

    return {
      email,
      requestPasswordReset,
      passwordResetEmailSent
    }
  }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>
